@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 30vh, 0);
  }
}

.App {
  overflow: hidden;
}

.App-logo-container {
  user-select: none;
  margin-top: 2vmin;
  animation: bounce 1.5s infinite cubic-bezier(0.5, 0.05, 1, 0.5) alternate;
}

.App-logo-container.paused {
  animation-play-state: paused;
}

.App-logo {
  animation: spin infinite 20s linear;
  height: 15vmin;
}

.App-header {
  color: goldenrod;
  background-color: #111;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.clock {
  user-select: none;
  font-size: 22vmin;
}
